import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import { redirectToCreditLogin, validateUimCookie } from '@/userauthentication';
import store from '@/store';
import routerInstance from '@/router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Morningstar Credit (previously MCIA)',
      metaTags: [
        {
          name: 'description',
          content: 'Morningstar Credit empowers credit market decision makers with tools and information that lead to faster, insightful, and more predictable outcomes.'
        },
        {
          name: 'keywords',
          content: 'CMBS,mortgage security,credit market,structured finance'
        },
        {
          property: 'og:description',
          content: 'Morningstar Credit empowers credit market decision makers with tools and information that lead to faster, insightful, and more predictable outcomes.'
        }
      ]
    },
  },
  {
    path: '/products/bank-analytics',
    name: 'BanksAnalytics',
    component: () => import('../views/BanksTool.vue'),
    meta: {
      title: 'Bank Analytics - Morningstar Credit (previously MCIA)',
    },
  },
  {
    path: '/products/banks-tool',
    beforeEnter() {
      routerInstance.push({ name: 'BanksAnalytics' });
    },
  },
  {
    path: '/analytics',
    beforeEnter() {
      routerInstance.push({ name: 'CREAnalytics' });
    },
  },
  {
    path: '/analytics/surveillance',
    beforeEnter() {
      routerInstance.push({ name: 'CMBSSurveillance' });
    },
  },
  {
    path: '/analytics/cmbsdata',
    beforeEnter() {
      routerInstance.push({ name: 'CMBSData' });
    },
  },
  {
    path: '/analytics/lead-gen',
    beforeEnter() {
      routerInstance.push({ name: 'LeadGen' });
    },
  },
  {
    path: '/analytics/report-stream',
    beforeEnter() {
      routerInstance.push({ name: 'ReportStream' });
    },
  },
  {
    path: '/products/corporate-analytics',
    name: 'CorporateAnalytics',
    component: () => import('../views/FundamentalsTool.vue'),
    meta: {
      title: 'Corporate Analytics - Morningstar Credit (previously MCIA)',
    },
  },
  {
    path: '/products/fundamentals-tool',
    beforeEnter() {
      routerInstance.push({ name: 'CorporateAnalytics' });
    },
  },
  {
    path: '/cre-analytics',
    name: 'CREAnalytics',
    component: () => import('../views/CreditAnalytics.vue'),
    meta: {
      title: 'CRE Analytics - Morningstar Credit (previously MCIA)',
    },
  },
  {
    path: '/products/clo',
    name: 'CloInsight',
    component: () => import('../views/CloInsight.vue'),
    meta: {
      title: 'CLO Insight Model - Morningstar Credit (previously MCIA)',
    },
  },
  {
    path: '/products/report-stream',
    name: 'ReportStream',
    component: () => import('../views/ReportStream.vue'),
    meta: {
      title: 'Report Stream - Morningstar Credit Products (previously MCIA)',
    },
  },
  {
    path: '/cre-analytics/surveillance',
    name: 'CMBSSurveillance',
    component: () => import('../views/CMBSSurveillance.vue'),
    meta: {
      title: 'CMBS Surveillance - Morningstar Credit Products (previously MCIA)',
    },
  },
  {
    path: '/cre-analytics/cmbsdata',
    name: 'CMBSData',
    component: () => import('../views/CMBSData.vue'),
    meta: {
      title: 'CMBSData - Morningstar Credit Products (previously MCIA)',
    },
  },
  {
    path: '/cre-analytics/lead-gen',
    name: 'LeadGen',
    component: () => import('../views/LeadGen.vue'),
    meta: {
      title: 'Lead Gen - Morningstar Credit Products (previously MCIA)',
    },
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/Disclaimer.vue"),
    meta: {
      title: 'Disclaimer - Morningstar Credit (previously MCIA)',
    },
  },
  {
    path: '/disclaimer-20210728-001',
    name: 'Disclaimer-20210728-001',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/Disclaimer-20210728-001.vue"),
    meta: {
      title: 'Disclaimer - Morningstar Credit (previously MCIA)',
    },
  },
  {
    path: '/disclaimer-20230919-002',
    name: 'Disclaimer-20230919-002',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/Disclaimer-20230919-002.vue"),
    meta: {
      title: 'Disclaimer - Morningstar Credit (previously MCIA)',
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsAndConditions.vue'),
    meta: {
      title: 'Terms and Conditions - Morningstar Credit (previously MCIA)',
    },
  },
  {
    path: '/download-research',
    name: 'Download-Research',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Download-Research.vue'),
    meta: {
      title: 'Download Research Article - Morningstar Credit (previously MCIA)',
    },
  },
  {
    path: '/research-list',
    name: 'Research-List',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Research-List.vue'),
    meta: {
      title: 'Research Articles - Morningstar Credit (previously MCIA)',
      metaTags: [
        {
          name: 'description',
          content: 'Research articles with unbiased opinions and independent property valuations that help empower investor success'
        },
        {
          name: 'keywords',
          content: 'CMBS Chronicle,credit opinion,research'
        },
        {
          property: 'og:description',
          content: 'Research articles with unbiased opinions and independent property valuations that help empower investor success'
        }
      ]
    },
  },
  {
    path: '/getstarted-thankyou',
    name: 'GetStartedThankYou',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/GetStartedThankYou.vue'),
    meta: {
      title: 'Thank you for your interest - Morningstar Credit (previously MCIA)',
      metaTags: [
        {
          name: 'robots',
          content: 'noindex,nofollow'
        }
      ]
    },
  },
  {
    path: '/videos/:id',
    name: 'videos',
    component: () => import(/* webpackChunkName: "about" */ '../views/Videos.vue'),
    meta: {
      title: 'Videos - Morningstar Credit (previously MCIA)',
    },
    props: true,
  },
  // {
  //   path: "/creditanalyticshub",
  //   name: "creditanalyticshub",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "dealview" */ "../views/CreditAnalyticsHub.vue"
  //     ),
  //   meta: {
  //     title: "Morningstar Credit Products - Credit Analytics Hub",
  //   },
  // },
  {
    path: '/newsflash/:id?',
    name: 'newsflash',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsFlash.vue'),
    meta: {
      title: 'NewsFlash - Morningstar Credit (previously MCIA)',
    },
    props: true,
  },
  {
    path: '/publish-research-article',
    name: 'PublishResearchArticle',
    component: () => import('../views/Publish-Research.vue'),
    beforeEnter: (to, from, next) => {
      // Make sure that the `next` function is called exactly once in any given pass through the
      // navigation guard. It can appear more than once, but only if the logical paths have no
      // overlap, otherwise the hook will never be resolved or produce errors.
      const successUrl = new URL(window.location.href);
      validateUimCookie().then((resp) => {
        if (resp?.valid) {
          if (resp.userRoles?.includes('ADMIN')) {
            store.dispatch('user/setAdminUser', true);
          } else {
            store.dispatch('user/setAdminUser', false);
          }
          store.dispatch('user/setUserName', resp?.userName);  
          next();
        } else if (!resp?.valid) {
          redirectToCreditLogin(successUrl);
          next(false);
        }
      });
    },
    meta: {
      title: 'Publish Research Article - Morningstar Credit (previously MCIA)',
    },
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AccessDenied.vue'),
  },
  {
    path: '*',
    name: 'Home',
    component: Home,
  },
];

const router = new VueRouter({
  mode: 'history',
  /*base: process.env.BASE_URL,*/
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    /*return { x: 0, y: 0 }*/
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
